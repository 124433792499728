<template>
  <div id="layout">
    <div
      class="bg-[#000000] fixed top-none h-full w-full z-30 opacity-50 cursor-pointer"
      v-if="overlay"
      @click="onOverlayClick"
    />

    <br-page :configuration="configuration" :mapping="componentMapping" :page="content">
      <template #default="{ page }">
        <template v-if="page">
          <div v-if="initComponent(page)" />
          <br-component component="menu" />
          <br-component component="shopByRoomMenu" />
          <br-component component="main-top" />

          <LazyLoginTray />
          <LazyBasketTray />
          <LazyBranchTray />

          <NotificationToast />

          <BranchSwitchModal />
          <BranchSwitchWarningModal />

          <main :class="isNavigating && 'min-h-[20000px]'">
            <slot :key="page" />
          </main>
          <br-component component="footer" />
        </template>
      </template>
    </br-page>
  </div>
</template>

<script lang="ts" setup>
const meta = ref([{}]);
const title = ref("");
const links = ref([{}]);
const { overlay, onOverlayClick } = useUIState();
const { componentMapping, initialize: initializeBloomreach, content, configuration, isNavigating } = useBloomreach();
const { fetchCategoryTree } = useCategoryTree();
const { connect: connectSession } = useSession();
const route = useRoute();
const { API_URL } = useRuntimeConfig().public;

useHead({
  title: title,
  meta: meta,
  htmlAttrs: {
    lang: "en",
  },
  link: links,
  bodyAttrs: {
    class: computed(() => {
      if (overlay.value) return "h-screen overflow-hidden ";

      return "";
    }),
  },
});

const data = (page: any) => {
  return page?.getDocument()?.getData();
};

const setPageSeo = (page: any) => {
  const basePath = `${API_URL}${route.path.replace(/\/+$/, "")}`;
  const urlParams = new URLSearchParams(route.fullPath.split("?")[1]);
  const paginationParam = urlParams.get("p") ? `?p=${urlParams.get("p")}` : "";

  links.value = [
    {
      rel: "canonical",
      href: `${basePath}${paginationParam}`,
    },
  ];
  meta.value = [
    {
      name: "description",
      hid: "description",
      content: data(page)?.metatags?.meta_description || data(page)?.description,
    },
    {
      name: "og:image",
      hid: "og:image",
      content: "",
    },
  ];

  if (route.path.includes("my-account") || route.path.includes("order-confirmation")) {
    meta.value.push({
      name: "robots",
      hid: "robots",
      content: "noindex, nofollow",
    });
  }

  title.value = data(page)?.metatags?.meta_title || data(page)?.title || "MKM";
};

const initComponent = (page: any) => {
  setPageSeo(page);
  return true;
};

await Promise.all([connectSession(), fetchCategoryTree(), initializeBloomreach()]);
</script>
