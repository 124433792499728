<template>
  <Modal v-if="user" :is-open="branchSwitchTray" data-test-id="switchToAccountBranch">
    <Stack tag="div" direction="col" gap="xl">
      <Stack tag="div" direction="col" gap="none">
        <Heading tag="h3" size="sm" class="text-charcoal-default mb-sm">
          Welcome back {{ user?.profile?.first_name }}
        </Heading>

        <Text tag="p" size="md">
          It looks like you're currently viewing products at our
          <span class="font-bold">
            {{ selectedBranch?.name }}
          </span>
          branch, but your account branch is
          <span class="font-bold"> {{ accountBranch?.name }} </span>. Would you like to switch to the
          <span class="font-bold">{{ accountBranch?.name }}</span> branch?
        </Text>
      </Stack>
      <Stack tag="div" slign="center" justify="end" gap="2xs" class="!w-full">
        <Button
          variant="neutral"
          size="md"
          @click="setBranchSwitchTray(false)"
          data-test-id="switchToAccountBranchNoBtn"
          >No Thanks</Button
        >
        <Button variant="secondary" size="md" @click="handleBranchSwitch" data-test-id="switchToAccountBranchYesBtn"
          >Yes</Button
        >
      </Stack>
    </Stack>
  </Modal>
</template>

<script setup lang="ts">
const { setBranchSwitchTray, branchSwitchTray, setMergeWarning } = useUIState();
const { user } = useUser();
const { accountBranch, selectedBranch, selectBranch } = useBranches();
const { fetchCart, merged } = useCart();

const branchToSwitchFrom = ref<string | null>(null);

const handleBranchSwitch = async () => {
  await selectBranch(Number(accountBranch?.value?.id));

  setBranchSwitchTray(false);

  await fetchCart();

  if (merged.value) {
    setMergeWarning(true, Number(branchToSwitchFrom.value));
  }
};

onMounted(() => {
  branchToSwitchFrom.value = selectedBranch?.value?.id ?? null;
});
</script>
